// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap Select
@import '~bootstrap-select/sass/bootstrap-select.scss';

// Date Range Picker
@import '~daterangepicker/daterangepicker.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

// Ekko Lightbox
@import "~ekko-lightbox/dist/ekko-lightbox.css";

@import "../css/app.css";
