input.datepicker {
    width: 100%;
    padding: 12px 40px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

input.datepicker2 {
    width: 100%;
    padding: 12px 40px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.input_col svg {
    position: absolute;
    left: 25px;
    top: 35px;
    color: black;
}

.input_col {
    position: relative;
}

.col label {
    display: block;
    margin-bottom: 0.2rem;
    font-weight: bold;
}

.col {
    margin-bottom: 1rem;
}

.col input.selectpicker {
    display: block;
}

.modal-footer {
    justify-content: left !important;
}
